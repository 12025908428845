import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { useEffect, useMemo, useState } from "react";
import { EnableDisableSwitch } from "@/components/Common/EnableDisableSwitch";

export type TimePickerProps = {
  disabled?: boolean;
  hour?: string;
  minute?: string;
  onChange?: (hour: string, minute: string) => void;
};

export const TimePicker = ({
  hour: extHour,
  minute: extMinute,
  onChange,
  ...props
}: TimePickerProps) => {
  const [twentyFourHour, setTwentyFourHour] = useState(false);
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");

  useEffect(() => {
    if (extHour !== undefined) {
      setHour(extHour);
    }
  }, [extHour]);

  useEffect(() => {
    if (extMinute !== undefined) {
      setMinute(extMinute);
    }
  }, [extMinute]);

  const generateHourOptions = () => {
    return Array.from({ length: 24 }, (_, i) => {
      const value = i.toString().padStart(2, "0");
      let label = value;
      if (!twentyFourHour) {
        const hour12 = ((i + 11) % 12) + 1;
        const period = i >= 12 ? "PM" : "AM";
        label = `${hour12.toString().padStart(2, "0")} ${period}`;
      } else {
        label = `${value}`;
      }
      return { value, label };
    });
  };

  const generateMinuteOptions = () => {
    return Array.from({ length: 60 }, (_, i) => {
      const minute = i.toString().padStart(2, "0");
      return { value: minute, label: minute };
    });
  };

  const handleHourChange = (value: string) => {
    if (onChange) {
      onChange(value, minute);
    }
    setHour(value);
  };

  const handleMinuteChange = (value: string) => {
    if (onChange) {
      onChange(hour, value);
    }
    setMinute(value);
  };

  const hourOptions = useMemo(() => generateHourOptions(), [twentyFourHour]);
  const minuteOptions = useMemo(() => generateMinuteOptions(), []);

  return (
    <div className="flex flex-row gap-2 text-primary">
      <Select value={hour} onValueChange={(value) => handleHourChange(value)}>
        <SelectTrigger className="w-24" disabled={props.disabled}>
          <SelectValue placeholder="Select Hour" />
        </SelectTrigger>
        <SelectContent>
          {hourOptions.map((option, index) => (
            <SelectItem key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Select
        value={minute}
        onValueChange={(value) => handleMinuteChange(value)}
      >
        <SelectTrigger className="w-24" disabled={props.disabled}>
          <SelectValue placeholder="Select Minute" />
        </SelectTrigger>
        <SelectContent>
          {minuteOptions.map((option, index) => (
            <SelectItem key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {false && (
        <EnableDisableSwitch
          className={"text-primary-foreground"}
          disabledText={"12 hr"}
          enabled={twentyFourHour}
          onChange={() => setTwentyFourHour(!twentyFourHour)}
          enabledText={"24 hr"}
          disabled={props.disabled}
        />
      )}
    </div>
  );
};
