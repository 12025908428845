import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { PatchCardDto } from "@/types/v2";
import {
  getEmployeeName,
  getEmployeeInitials,
  getServiceAdvisorColor,
} from "@/lib/employees";
import { cn } from "@/lib/utils";
import { useStore } from "@/stores/useStore";

interface CardTechSAProps {
  cardData: PatchCardDto;
}

export const CardTechSALabel = ({ cardData }: CardTechSAProps) => {
  const [open, setOpen] = useState(false);
  const employees = useStore((s) => s.employees);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={cn(
          "absolute top-1 cursor-pointer z-5 p-0 rounded-full text-accent hover:scale-105",
          cardData.source === "manual" ? "right-4" : "right-0"
        )}
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="w-5 h-5 rounded-full flex justify-center items-center border border-white"
          style={{
            backgroundColor: getServiceAdvisorColor(
              cardData?.serviceWriterId,
              employees || []
            ),
          }}
        >
          <div className="text-white text-[10px] font-bold">
            {getEmployeeInitials(cardData?.technicianId, employees || [])}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-2 p-2"
      >
        <div>
          <p>
            <strong>Service Advisor:</strong>{" "}
            {getEmployeeName(cardData?.serviceWriterId, employees || [])}
          </p>
          <p>
            <strong>Technician:</strong>{" "}
            {getEmployeeName(cardData?.technicianId, employees || [])}
          </p>
        </div>
      </PopoverContent>
    </Popover>
  );
};
