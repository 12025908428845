import { TimeInContainerMetric } from "@/types/v2/TimeInContainer";
import { generic } from "@/models/generic";
import { subDays } from "date-fns";

export const TimeInContainerMetricsRepository = {
  subscribe: (
    shopId: string,
    callback: (metrics: TimeInContainerMetric[]) => void
  ) => {
    generic.subscribeCollection<TimeInContainerMetric>(
      `shops/${shopId}/metrics`,
      callback,
      "metricDateStart",
      ">=",
      subDays(new Date(), 7)
    );
  },
};
