import { useEffect, useState } from "react";
import { EditSaveButtons } from "./EditSaveButtons";
import { cn } from "@/lib/utils";
import { ClassNameValue } from "tailwind-merge";
import { TimePicker, TimePickerProps } from "@/components/Base/TimePicker";

interface EditSaveInputProps extends TimePickerProps {
  value: string;
  onSave: (arg: string | undefined) => void;
  containerClassName?: ClassNameValue;
  disabled?: boolean;
}

export const EditSaveTime = ({
  value,
  hour,
  minute,
  onSave,
  containerClassName,
  disabled,
  ...props
}: EditSaveInputProps) => {
  const [newHour, setNewHour] = useState("00");
  const [newMinute, setNewMinute] = useState("00");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (hour) {
      setNewHour(hour);
    }
  }, [hour]);
  useEffect(() => {
    if (minute) {
      setNewMinute(minute);
    }
  }, [minute]);

  useEffect(() => {
    setNewHour(value.split(":")[0]);
    setNewMinute(value.split(":")[1]);
  }, [value]);

  const handleChange = (hour: string, minute: string) => {
    setNewMinute(minute);
    setNewHour(hour);
  };

  const handleEdit = () => setIsEditing(true);
  const handleDiscard = () => {
    setIsEditing(false);
    setNewHour(value.split(":")[0]);
    setNewMinute(value.split(":")[1]);
  };

  const handleSave = () => {
    onSave(`${newHour}:${newMinute}`);
    setIsEditing(false);
  };

  return (
    <div
      className={cn(
        "flex flex-row flex-grow justify-between align-middle",
        containerClassName
      )}
    >
      {isEditing ? (
        <TimePicker
          hour={newHour}
          minute={newMinute}
          onChange={handleChange}
          {...props}
        />
      ) : (
        <div className="flex h-10 w-full rounded-md px-3 py-2 text-sm text-primary-foreground items-center">
          {value}
        </div>
      )}
      <EditSaveButtons
        containerClassName={cn("w-48 justify-end", disabled && "hidden")}
        isEditing={isEditing}
        handleDiscard={handleDiscard}
        handleEdit={handleEdit}
        handleSave={handleSave}
      />
    </div>
  );
};
