import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from "react";
import { cn } from "@/lib/utils";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";

interface DeltaDisplayModeContextType {
  displayMode: number;
  setDisplayMode: React.Dispatch<React.SetStateAction<number>>;
}

const DeltaDisplayModeContext = createContext<
  DeltaDisplayModeContextType | undefined
>(undefined);

export const DeltaDisplayModeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [displayMode, setDisplayMode] = useState<number>(() => {
    const savedMode = localStorage.getItem("deltaDisplayMode");
    return savedMode ? Math.min(parseInt(savedMode, 10), 1) : 0;
  });

  useEffect(() => {
    localStorage.setItem("deltaDisplayMode", displayMode.toString());
  }, [displayMode]);

  return (
    <DeltaDisplayModeContext.Provider value={{ displayMode, setDisplayMode }}>
      {children}
    </DeltaDisplayModeContext.Provider>
  );
};

export const useDeltaDisplayMode = (): DeltaDisplayModeContextType => {
  const context = useContext(DeltaDisplayModeContext);
  if (!context) {
    throw new Error(
      "useDeltaDisplayMode must be used within a DisplayModeProvider"
    );
  }
  return context;
};

const DeltaDisplay = ({
  size,
  delta,
  units,
  children,
}: PropsWithChildren & {
  size?: "xs" | "sm" | "base";
  delta?: number;
  units?: string;
}) => {
  const { displayMode, setDisplayMode } = useDeltaDisplayMode();

  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 2);
  };

  const iconSize = (size: "xs" | "sm" | "base" | undefined) => {
    if (!size) return 16;
    switch (size) {
      case "xs":
        return 12;
      case "sm":
        return 14;
      default:
        return 16;
    }
  };

  return (
    <div
      onClick={(e) => {
        toggleDisplayMode();
        e.stopPropagation();
      }}
      className={cn(
        "text-center cursor-pointer text-nowrap",
        size === "sm" && "text-sm",
        size === "xs" && "text-xs"
      )}
    >
      {displayMode === 0 && children}
      {displayMode === 1 && (
        <div className={"flex flex-row items-center"}>
          <span>
            {delta ? (
              delta > 0 ? (
                <ArrowUpIcon
                  size={iconSize(size)}
                  className={"text-red font-bold"}
                />
              ) : (
                <ArrowDownIcon
                  size={iconSize(size)}
                  className={"text-green font-bold"}
                />
              )
            ) : null}
          </span>
          <span>{delta}</span>
          <span>{units}</span>
        </div>
      )}
    </div>
  );
};

export default DeltaDisplay;
