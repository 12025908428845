import { Label } from "../Base/Label";
import { Switch } from "../Base/Switch";
import { ClassNameValue } from "tailwind-merge";
import { cn } from "@/lib/utils";

export interface EnableDisableSwitchProps {
  label?: string;
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
  enabledText?: string;
  disabledText?: string;
  className?: ClassNameValue;
}
export const EnableDisableSwitch = ({
  label,
  enabled,
  onChange,
  disabled,
  enabledText,
  disabledText,
  className,
}: EnableDisableSwitchProps) => {
  if (label === undefined) {
    return (
      <div
        className={cn(
          "flex flex-row justify-between gap-2 items-center",
          className
        )}
      >
        <Label>{disabledText ?? "Disabled"}</Label>
        <Switch
          checked={enabled}
          onCheckedChange={onChange}
          disabled={disabled}
        />
        <Label>{enabledText ?? "Enabled"}</Label>
      </div>
    );
  }
  return (
    <div
      className={cn(
        "flex flex-row justify-between gap-2 items-center",
        className
      )}
    >
      <div>{label}</div>
      <div className="flex flex-row justify-between gap-2 items-center">
        <Label>{disabledText ?? "Disabled"}</Label>
        <Switch
          checked={enabled}
          onCheckedChange={onChange}
          disabled={disabled}
        />
        <Label>{enabledText ?? "Enabled"}</Label>
      </div>
    </div>
  );
};
