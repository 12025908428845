export const WorkingDays = {
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
} as const;

export type WorkingDays = (typeof WorkingDays)[keyof typeof WorkingDays];

export type WorkingDay = {
  startTime: string;
  endTime: string;
  isClosed: boolean;
};

export type ShopConfig = {
  // eslint-disable-next-line no-unused-vars
  workingHours: { [key in WorkingDays]: WorkingDay };
};
